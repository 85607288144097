'use strict';

angular.module('mvpcarrefourApp')
  .controller('ManageCategoriesCtrl', ['$scope', 'Category', 'Modal',
    function($scope, Category, Modal) {

      Category.list({ parent: true }).then(categories => {
        $scope.categories = categories;
        // pagination controls
        $scope.currentPage = 1;
        $scope.totalItems = $scope.categories.length;
        $scope.itemsPerPage = 10; // items per page
        $scope.noOfPages = Math.ceil($scope.totalItems / $scope.itemsPerPage);
      });

      $scope.deleteCategory = Modal.confirm.delete(function(c) {
        if (c.slug == 'all') return;
        Category.delete(c, function(resp) {
          $scope.categories.splice($scope.categories.indexOf(c), 1);
        })
      });
    }
  ])

.controller('ManageCategoriesEditCtrl', ['$scope', '$state', 'Category', '$stateParams', 'Modal', 'Upload',
  function($scope, $state, Category, $stateParams, Modal, Upload) {

    Category.list({ parent: true }).then(categories => {
      $scope.categories = categories;
      $scope.category = Category.one.get({ id: $stateParams.id });
    });

    $scope.uploadImage = function(file) {
      var options = {width: 150, height: 150, type: 'image/*', centerCrop: true, restoreExif: false};
      Upload.resize(file, options)
        .then(function(resizedFile) {
          Upload.upload({
            url: '/api/uploads',
            data: { file: resizedFile }
          })
            .then(function(resp) {
              $scope.category.image = resp.data.url;
            }, function(resp) {
              $scope.errorMsg = resp.status + ': ' + resp.data;
            }, function(evt) {
              var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
        })
    };

    $scope.save = function(form) {
      if (form.$valid) {
        if (category.slug == 'all') return;

        Category.update($scope.category, Modal.confirm.info({
            title: "Categoría creada",
            msg: "La categoria ha sido actualizada correctamente."
          },function() {
            $state.go('manage-categories')
          }),
          Modal.confirm.info({
            title: "Error",
            msg: "Ha ocurrido un error al actualizar la categoria"
          },function(err) {
            console.log(err)
          })
        );
      }
    }
  }
])

.controller('ManageCategoriesAddCtrl', ['$scope', '$state', 'Category', '$stateParams', 'Modal', 'Upload',
  function($scope, $state, Category, $stateParams, Modal, Upload) {

    $scope.category = {
      image: ""
    }

    Category.list({}).then(categories => {
      $scope.categories = categories;
    });

    $scope.uploadImage = function(file) {
      var options = {width: 150, height: 150, type: 'image/*', centerCrop: true, restoreExif: false};
      Upload.resize(file, options)
        .then(function(resizedFile) {
          Upload.upload({
            url: '/api/uploads',
            data: { file: resizedFile }
          })
            .then(function(resp) {
                $scope.category.image = resp.data.url;
            }, function(resp) {
              $scope.errorMsg = resp.status + ': ' + resp.data;
            }, function(evt) {
              var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            });
        })
    };

    $scope.save = function(form) {
      if (form.$valid) {

        Category.create($scope.category, Modal.confirm.info({
            title: "Categoría creada",
            msg: "La categoria ha sido creada correctamente."
          },function() {
            $state.go('manage-categories')
          }),
          Modal.confirm.info({
            title: "Error",
            msg: "Ha ocurrido un error al crear la categoría"
          },function(err) {
            $scope.message = err;
          })
        );
      }
    }
  }
]);
